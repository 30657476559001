import React, { useEffect, useReducer, useState } from "react";
import usuarioContext from "./usuarioContext";
import usuarioReducer from "./usuarioReducer";
import clienteAxios from "../../config/axios";

import {
    OBTENER_USUARIO,
    OBTENER_USUARIO_ERROR,
    CREAR_CUENTA,
    RESETEAR_CARGANDO, ACTUALIZAR_ESTADO_USUARIO, RESETEAR_GUARDANDO, RESETEAR_MENSAJE, ACTUALIZAR_USUARIO, OBTENER_CARGAS, OBTENER_CARGAS_ERROR, OBTENER_NIVEL, OBTENER_NIVEL_ERROR, OBTENER_NIVELES, CANJEAR_CUPON_COMBUSTIBLE, MENSAJE_USUARIO_ERROR, OBTENER_HISTORIAL_CARGAS, PRIMERA_CARGA_REALIZADA, OBTENER_BENEFICIOS_NIVELES, SET_TUTORIAL_FALSE, GET_HOME, ACTUALIZAR_CONTACTO
} from "../../types";


const UsuarioState = props => {
    const initialState = {
        usuario: null,
        contacto: null,
        mensaje: null,
        cargasCombustible: null,
        cuponCombustible: null,
        historialCargas: null,
        cargando: true,
        nivel: null,
        niveles: null,
        redirect: false,
        guardando: false,
        primeraCargaRealizadaMostrar: false,
        novedades: null,
        beneficiosExclusivos: null,
        verified: null
    }

    useEffect(() => {

        clienteAxios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalConfig = error.config;
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.replace("/");
                    }
                }
                return Promise.reject(error);
            }
        );

    }, [])

    const [state, dispatch] = useReducer(usuarioReducer, initialState);

    const [isMountedCuponCombustible,setIsMountedCuponCombustible] = useState(false);

    const obtenerUsuario = async () => {

        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/getcontact`);
            dispatch({
                type: OBTENER_USUARIO,
                payload: respuesta.data.response,
            })

        } catch (error) {
            const alerta = {
                msg: 'Ha ocurrido un error al obtener el usuario.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_USUARIO_ERROR,
                payload: alerta,
            })
            //resetearMensaje();
        }
    }

    const actualizarUsuario = async (datos) => {
        try {
            resetearCargando();

            const respuesta = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/updatecontact`, datos);
            if (respuesta.data?.statusCode == 206) {
                enviarError(respuesta);
            }
            else {
                const alerta = {
                    msg: respuesta.data.message,
                    categoria: respuesta.data.type,
                }

                dispatch({
                    type: ACTUALIZAR_USUARIO,
                    payload: alerta,
                })

                resetearCargando();
                obtenerUsuario();

                window.dataLayer.push({ "event": "click_guardar_perfil" });
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    const resetearGuardando = () => {
        dispatch({
            type: RESETEAR_GUARDANDO,
        })
    }

    const resetearMensaje = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const actualizarEstadoUsuario = (datos) => {
        dispatch({
            type: ACTUALIZAR_ESTADO_USUARIO,
            payload: datos,
        })
    }

    const crearCuenta = async (datos) => {
        try {
            resetearGuardando();
            var respuesta = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/createcontact/`, datos)
            if (respuesta.data?.type == "warning") {
                window.dataLayer.push({
                    "event": "click_resgistrarse_error",
                    "category": respuesta.data?.detail
                })
                enviarError(respuesta);
            }
            else {
                dispatch({
                    type: CREAR_CUENTA,
                    payload: datos,
                })
            }

            window.gtag('event', 'click', {
                'event_category': 'registro_3',
                'location_id': window.location.href,
                'value': '10'
            });
            window.dataLayer.push({
                "event": "sign_up",
                "category": "Email"
            });

        } catch (err) {
            var error = null;
            if (err.response !== undefined) error = err.response;
            if (err.data !== undefined) error = err;

            window.dataLayer.push({
                "event": "click_resgistrarse_error",
                "category": error !== undefined ? error.data.detail : "El servicio no esta disponible"
            })
            enviarError(err);
        }
    }

    const obtenerCargas = async () => {
        try {

            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/getcargascombustible`);

            dispatch({
                type: OBTENER_CARGAS,
                payload: respuesta.data.response,
            })

        } catch (error) {
            const alerta = {
                msg: 'Ha ocurrido un error al obtener las cargas del usuario.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_CARGAS_ERROR,
                payload: alerta,
            })

        }
    }


    const obtenerNivel = async () => {

        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/getnivel`);


            if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                throw "";
            }

            dispatch({
                type: OBTENER_NIVEL,
                payload: respuesta.data.response,
            })

        } catch (error) {
            const alerta = {
                msg: 'Ha ocurrido un error al obtener el nivel del usuario.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_NIVEL_ERROR,
                payload: alerta,
            })

        }
    }


    const obtenerNiveles = async () => {
        try {

            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_INFORMACION}/getNiveles`);
            dispatch({
                type: OBTENER_NIVELES,
                payload: respuesta.data,
            })

        } catch (error) {
            const alerta = {
                msg: 'Ha ocurrido un error al obtener los niveles.',
                categoria: 'alert-danger',
            }
            dispatch({
                type: OBTENER_NIVEL_ERROR,
                payload: alerta,
            })

        }
    }

    const obtenerBeneficiosNiveles = async (datos) => {
        try {
            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_BENEFICIOS}/getbeneficiosbynivel/${datos}`);
            if (respuesta.data?.statusCode == 205 || respuesta.data?.statusCode == 206) {
                enviarError(respuesta);
            }
            else {
                dispatch({
                    type: OBTENER_BENEFICIOS_NIVELES,
                    payload: {
                        nivel: datos,
                        beneficios: respuesta.data.response
                    },
                })
            }
        } catch (err) {
            enviarError(err);
        }
    }

    const canjearCuponCombustible = async () => {
        let respuesta = {}
        try {
            resetearCargando();
            respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CUPONES}/canjeCuponCombustiblev2`);

            if (respuesta.data?.statusCode == 209 || respuesta.data?.statusCode == 206) {
                enviarError(respuesta);
            }
            else {
                if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                    throw "";
                }
                dispatch({
                    type: CANJEAR_CUPON_COMBUSTIBLE,
                    payload: respuesta.data?.response,
                })

                window.dataLayer.push({ "event": "click_quintaCarga" });

                resetearCargando();
                obtenerCargas();
            }

        } catch (err) {
            enviarError(err);
        }
        
        if (respuesta.data?.statusCode == 200) {
            try{
                enviarNotificacion();
            }
            catch(ex){

            }
           
        }
    }

    const obtenerHistorialCargas = async () => {
        try {

            const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/getcargas`);
            if (respuesta.data?.statusCode == 206) {
                enviarError(respuesta);
            }
            else {
                dispatch({
                    type: OBTENER_HISTORIAL_CARGAS,
                    payload: respuesta.data?.response,
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const realizoUltimaCarga = async () => {

        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/getrealizoprimeracarga`)
            .then(r => {

                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    if (r.data.response == "Mostrar") {
                        dispatch({
                            type: PRIMERA_CARGA_REALIZADA,
                            payload: true
                        })
                        // return true;
                    }
                    if (r.data.response == "No mostrar") {
                        dispatch({
                            type: PRIMERA_CARGA_REALIZADA,
                            payload: false
                        })
                        // return false;
                    }
                }

            })
            .catch(e => {
                enviarError(e);
            })
    }
    const obtenerHome = async () => {
        resetearCargando();
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/gethome`)
            .then((r) => {
                if (r.data?.statusCode == 206) {
                    enviarError(r);
                }
                else {
                    dispatch({
                        type: GET_HOME,
                        payload: r.data.response
                    })
                }
            })
            .catch((e) => {
                enviarError(e);
            })
    }
    const autenticarMailB2C = async () => {
        await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/createemailauth`)
            .then((r) => {
            })
            .catch((e) => {
            })
    }

    

    const setTutorialFalse = async () => {
        try {
            var respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/settutorialfalse`);
            if (respuesta.data?.statusCode == 206) {
                enviarError(respuesta);
            }
            else {
                dispatch({
                    type: SET_TUTORIAL_FALSE,
                })
                
            }
        } catch (err) {
            enviarError(err);
        }
    }

    const enviarNotificacion=async()=>{
        try{
            var respuesta = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/enviarNotificacion`,{"notificationName":state.contacto.notificacionQuintaCarga}).then(respuesta => {
            if (respuesta?.status === 200) {
                // console.log("La notificacion se envio con exito");
               
            }
            else{
                // enviarError(respuesta)
            }
           
           
        })
        }
 
        catch(err){
            // enviarError(err);
        }
       
    }


    const isPerfilCompleto=async()=>{
        
        
            try{
            
                const respuesta = await clienteAxios.get(`${process.env.REACT_APP_API_CONTEXT_CONTACTOS}/isperfilcompleto`);
                    if (respuesta?.status === 200 || respuesta?.status === 204) {
                        return respuesta.status;
                       
                    }
            
        }
 
        catch(err){
            enviarError(err);
            return 500;
        }
        
        
       
    }

    const fetchCodigoVerificacion = async (data) => {
        try {

            resetearCargando();
            
            var response = await clienteAxios.post(`${process.env.REACT_APP_API_CONTEXT_APID365}/codigo-verificacion`, data);

            dispatch({
                type: "FETCH_CODIGO_VERIFICACION",
                payload: response.data.response
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const resetVerified = () => {
        dispatch({
            type: "RESET_VERIFIED",
            payload: null
        })
    }

    const setContacto = (nuevoContacto) => {
        dispatch({
          type: ACTUALIZAR_CONTACTO,
          payload: nuevoContacto,
        });
      };

    const enviarError = (err) => {
        var error = null;
        if (err.response !== undefined) error = err.response;
        if (err.data !== undefined) error = err;
        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_USUARIO_ERROR,
            payload: alerta,
        })
    }

    return (
        <usuarioContext.Provider
            value={{
                usuario: state.usuario,
                contacto: state.contacto,
                mensaje: state.mensaje,
                nivel: state.nivel,
                cargando: state.cargando,
                cargasCombustible: state.cargasCombustible,
                cuponCombustible: state.cuponCombustible,
                historialCargas: state.historialCargas,
                redirect: state.redirect,
                niveles: state.niveles,
                guardando: state.guardando,
                primeraCargaRealizadaMostrar: state.primeraCargaRealizadaMostrar,
                novedades: state.novedades,
                beneficiosExclusivos: state.beneficiosExclusivos,
                verified: state.verified,
                isMountedCuponCombustible,
                obtenerUsuario,
                crearCuenta,
                obtenerCargas,
                obtenerNivel,
                resetearCargando,
                actualizarUsuario,
                resetearGuardando,
                obtenerNiveles,
                obtenerBeneficiosNiveles,
                obtenerHistorialCargas,
                canjearCuponCombustible,
                resetearMensaje,
                actualizarEstadoUsuario,
                realizoUltimaCarga,
                obtenerHome,
                setTutorialFalse,
                autenticarMailB2C,
                enviarError,
                setIsMountedCuponCombustible,
                isPerfilCompleto,
                fetchCodigoVerificacion,
                resetVerified,
                setContacto,
            }}>
            {props.children}
        </usuarioContext.Provider>
    )
}
export default UsuarioState;